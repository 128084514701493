var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column flex-root" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
        style: { "background-image": "url(" + _vm.bgSrc + ")" }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto mt-12"
              },
              [
                _c("ValidationObserver", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var invalid = ref.invalid
                        return [
                          _c(
                            "form",
                            {
                              staticClass: "form w-100",
                              attrs: { name: "form" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center mb-7 mt-5" },
                                [
                                  _c("a", { staticClass: "mb-12" }, [
                                    _c("img", {
                                      staticClass: "h-150px",
                                      attrs: { alt: "Logo", src: _vm.bgLogo }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "h1",
                                    { staticClass: "text-success mb-3 mt-5" },
                                    [
                                      _vm._v("تسجيل دخول المواطنين"),
                                      _c("br"),
                                      _vm._v("نظام خدمات البلدية")
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "fv-row" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-lg btn-success w-100 mb-5 btn-block",
                                      attrs: { type: "button", href: _vm.url }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    اضغط لتسجيل الدخول\n                                "
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4a13a6f8", { render: render, staticRenderFns: staticRenderFns })
  }
}