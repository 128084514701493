<template>
  <!--begin::Main-->
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <Aside></Aside>
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

        <!--begin::Header-->
        <Header></Header>
        <!--end::Header-->
        <!--begin::Content-->
        <router-view />
        <!--end::Content-->
        <!--begin::Footer-->
        <Footer></Footer>
        <!--end::Footer-->
      </div>

      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <!--end::Root-->
  <!--begin::Modals-->

  <!--end::Modals-->
  <!--begin::Scrolltop-->

  <!--end::Scrolltop-->
  <!--end::Main-->
</template>

<script>

import ApiServices from '../core/services/api.services';
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Aside from "../layout/Aside";
// import Header from "@/layout/Header";
// import Footer from "@/layout/Footer";
// import Aside from "@/layout/Aside";
export default {
  name: "Home",
  components: { Header, Footer, Aside },
  data() {
    return {
        name: "",
        id_number: ""
    };
  },
  created() {
      const card = localStorage.getItem('user');
      const token = localStorage.getItem('token');
      const pname = localStorage.getItem('name');
      const ntoken = localStorage.getItem('ntoken');
      if ((!card || !token || !pname || !ntoken)) {
        this.$router.push('/login');
      }


  },
};
</script>

<style scoped >
</style>