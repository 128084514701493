import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import config from './config';

/**
 * Service to call HTTP request via Axios
 */
const ApiServices = {
  init() {
    Vue.use(VueAxios, axios);
    // axios.defaults.baseURL = "http://localhost/";
  },
  getMainCpPath(){
    return config.mainUrl;
  },
  getStoragePath(){
    return config.storageUrl;
  },
  getAttachmentsUrl(){
    return config.attachmentsUrl;
  },
  getapiUrl(){
    return config.apiUrl;
  },
  getHeader() {

      var token = localStorage.getItem('token');
      var ntoken = localStorage.getItem('ntoken');

      if ((!token || !ntoken)) {
          return {};
      } else {
        return { 'Authorization': 'Bearer ' + token ,'nAuthorization': 'Bearer ' + ntoken };
       }
  },

  query(resource, params) {
    return axios.get(`${config.apiUrl}${resource}`, { headers: this.getHeader(), params : {...params} }).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },
  
  filter(resource, params) {
    return axios.post(`${config.apiUrl}${resource}/filter`, params, { headers: this.getHeader() }).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },
    downloadFilter(resource,params) {
        return axios.post(`${config.apiUrl}${resource}`, params,{ headers: this.getHeader() , responseType: 'arraybuffer'}).catch(error => {
            throw new Error(`[KT] ApiService ${error}`);
    });
    },

  get(resource) {
    return axios.get(`${config.apiUrl}${resource}`, { headers: this.getHeader() }).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

    download(resource) {
    return axios.get(`${config.apiUrl}${resource}`, { headers: this.getHeader() , responseType: 'arraybuffer'}).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  getOptions(resource) {
    return axios.get(`${config.apiUrl}${resource}`, { headers: this.getHeader() }).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return axios.post(`${config.apiUrl}${resource}`, params, { headers: this.getHeader() });
  },

  update(resource, slug, params) {
    return axios.put(`${config.apiUrl}${resource}/${slug}`, params, { headers: this.getHeader() });
  },

  put(resource, params) {
    return axios.put(`${config.apiUrl}${resource}`, params, { headers: this.getHeader() });
  },



  delete(resource) {
    // return axios.delete('http://localhost/_mobadarat-backend/public/api/v1.0/Admin/'+`${resource}`, { headers: this.getHeader() }).catch(error => {
    //   throw new Error(`[RWV] ApiService ${error}`);
    // });

      let url =  config.apiUrl + resource ;

      return axios.delete(url, { headers: this.getHeader() }).catch(error => {
          throw new Error(`[RWV] ApiService ${error}`);
  });
  },

};

export default ApiServices;
