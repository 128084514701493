const mainUrl  = 'https://portal.nuseirat.ps/';
const apiUrl  = 'https://nuseirat.ps/portal-api/public/api/v1.0/Portal/';
const storageUrl='https://nuseirat.ps/portal-api/public/documents/';
const attachmentsUrl='https://nuseirat.ps/portal-api/public/attachments/';

// const mainUrl  = 'http://localhost/';
// const apiUrl  = 'https://localhost/portal-backend/public/api/v1.0/Portal/';
// const storageUrl='https://localhost/portal-backend/public/documents/';
// const attachmentsUrl='https://localhost/portal-backend/public/attachments/';

export default {mainUrl,apiUrl,storageUrl,attachmentsUrl};
